import React from 'react';
const FutureContent = () => {
    return (
        <div className="coming-soon-area">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="coming-soon-content" style={{ maxHeight: "600px", maxWidth: "600px" }}>
                        <div className="circle">
                            <div style={{ padding: 50 }}>
                                <img src="/images/logo.svg" style={{ width: 150, height: 150, marginBottom: 20 }} />
                                <h3>Content Coming Soon</h3>
                                <p>
                                    The Content will be available soon.
                                    The page you are looking for may be under maintenance or development.
                                    We are extremly sorry for the Inconveinience Occured!
                                </p>
                                <a href="/" className="default-btn">
                                    Visit Homepage
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default FutureContent;